<template>
	<div class="footer-container">
		<div class="footer-content">
			<div class="footer-logo centering" :class="logoFooter" >
				<div class="inner"></div>
			</div>
			<div class="seperator"></div>
			<div class="footer-info centering">
				<ul class="footer-list list-unstyled">
					<li>
						Πραξιτέλους 169 & Μπουμπουλίνας, 185 35 Πειραιάς
					</li>
					<li>
						T: +30 216 9003700
					</li>
					<li>
						E: info@laskaridisfoundation.org
					</li>
				</ul>
			</div>
		</div>
		<div class="pavla">
			© copyright 2025 <a href="https://www.pavla.gr" target="_blank"> Pavla S.A.</a>, All Rights Reserved.
		</div>
	</div>
</template>

<script>
import Vuex from 'vuex'

export default {
	name: "AppFooter",
	computed: {
		...Vuex.mapState(['logoFooter'])
	},
	methods: {
		setLogoMethod() {
			if (this.$route.name === 'archives' && this.$route.params.id === '42360') {
				this.$store.dispatch('updateLogoFooter', 'footer-logo-blue')
			} else if (this.$route.name === 'profile' && this.$route.params.id === '42360') {
				const cookies = document.cookie.split(';').map(cookie => cookie.trim())
				const isSecondaryCookie = cookies.find(cookie => cookie.startsWith('isSecondary='))
				if (isSecondaryCookie) {
					this.$store.dispatch('updateLogoFooter', 'footer-logo-blue')
				} else {
					this.$store.dispatch('updateLogoFooter', 'footer-logo')
				}
			} else {
				this.$store.dispatch('updateLogoFooter', 'footer-logo')
			}
		}
	},
	mounted() {
		this.setLogoMethod()
	},
	watch: {
		'$route': 'setLogoMethod'
	}
}
</script>

<style scoped lang="scss">
.footer-container {
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $footer-blue;
}

.footer-content {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	width: 50%
}

.footer-logo {
	padding: 10% 2%;
	height: 14vw;
}

.footer-logo .inner {
	background-image: url('../assets/images/logo_white.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 50%;
	width: 12vw;
	height: 5vw;
}
.footer-logo-blue .inner {
	background-image: url('../assets/images/logo_laskaridis_blue.png');
}

.seperator {
	height: 74px;
	border: 1px solid #fff;
	margin: 10% 5% 0 -5%;
	width: 1px;
}

.footer-info {
	color: #fff;
	font-size: 15px;
}

.footer-list {
	margin-top: 7%;
	text-align: left;
	padding: 0;
	width: 100%;
}

.pavla {
	height: 40px;
	width: 100%;
	background-color: #000;
	color: #fff;
	text-align: center;
	padding: 1% 0 2.5% 0;
	font-size: 0.8rem;
}

.pavla a {
	color: $tur-blue;
}

@media(max-width: 1450px) {
	.seperator {
		// margin: 12% 5% 0 -5%;
		// height: 70px;
	}
}

@media(max-width: 1200px) {

		.seperator {
			margin: 8% 7% 0 -1%;
		}

		.footer-logo {
			padding: 7%;

		.inner {
				height: 100%;
				width: 14vw;
		}
	}
}

@media (max-width: 960px) {
	.footer-content {
		// flex-direction: column;
    width: 100%;
		padding: 2%;
		justify-content: space-evenly;
	}
	.seperator {
		margin: 4% -5% 0 -5%;
		height: 62px;
	}

  .footer-logo {
    padding: 0;
		padding-top: 2%;
		padding-left: 13%;
		height: 13vw;
  }

	.footer-logo .inner {
    height: 90%;
    width: 16vw;
		background-position: center;
	}

  .footer-info {
    align-items: flex-start!important;
  }
}

@media (max-width: 775px) {
	.seperator {
		display: none;
	}

	.footer-content {
		flex-direction: column;

			.footer-logo {
				padding-left: 0;
				padding-top: 5%;
				height: 18vw
			}

			.footer-logo .inner {
				width: 27vw;
			}

			.footer-list {
				text-align: center;
				margin-top: 3%;
				margin-bottom: 3%;
			}
	}
}

@media (max-width: 550px) {
	.footer-content .footer-logo .inner{
		width: 32vw;
	}

	.footer-info {
		font-size: 13px;
	}

	.pavla {
		font-size: 11px;
	}
}

@media (max-width: 420px) {

}

</style>
